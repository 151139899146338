import React from 'react';
import "./AboutCompo.css"
import WhoImg from "../../assets/Picture1.jpg"
const AboutCompo = () => {
  return (
    <div style={{marginTop:"100px"}} className='continer widthHome'>
      <div  className='font aboutTitle'>WHO WE ARE?</div>

      <div className='flexAbout'>
        <div>
          <img src={WhoImg} alt='p' style={{width:"300px"}}></img>
        </div>
        <div className='font aboutCompo' >Watad is a Limited liability company established in 2021, to provide comprehensive and consultation services across a wide range of industries. Founded by a group of seasoned and licensed professionals who possess exceptional interpersonal, problem-solving and analytical skills to provide advice and expertise to client organizations improving business performance.</div>
      </div>


    </div>
  );
}

export default AboutCompo;
