import React from 'react';
import { CiStar } from "react-icons/ci";
import { BsBagPlusFill } from "react-icons/bs";
import "./ServicesCompo.css"
const ServicesCompo = () => {
    return (
        <div style={{ marginTop: "100px" }} className='continer widthHome'>

            <div className='font serviceTitle' >
                Our Services
                <CiStar />
            </div>

            <div className='sirveceMobile servicesContiner' >

                <div className='serviceCard'>
                    <h1 className='font serviceCardTitle' >Services no one</h1>
                    <div style={{ color: "#65C5B9" }}>
                        <BsBagPlusFill size={70} />
                    </div>
                    <h1 className='font serviceCardDescription' >Lorem ipsum dolor sit amet consectetur. Lacus tincidunt lectus volutpat pharetra velit morbi hendrerit semper.</h1>
                </div>

                <div className='serviceCard'>
                    <h1 className='font serviceCardTitle' >Services no one</h1>
                    <div style={{ color: "#65C5B9" }}>
                        <BsBagPlusFill size={70} />
                    </div>
                    <h1 className='font serviceCardDescription' >Lorem ipsum dolor sit amet consectetur. Lacus tincidunt lectus volutpat pharetra velit morbi hendrerit semper.</h1>
                </div>

                <div className='serviceCard'>
                    <h1 className='font serviceCardTitle' >Services no one</h1>
                    <div style={{ color: "#65C5B9" }}>
                        <BsBagPlusFill size={70} />
                    </div>
                    <h1 className='font serviceCardDescription' >Lorem ipsum dolor sit amet consectetur. Lacus tincidunt lectus volutpat pharetra velit morbi hendrerit semper.</h1>
                </div>


                <div className='serviceCard'>
                    <h1 className='font serviceCardTitle' >Services no one</h1>
                    <div style={{ color: "#65C5B9" }}>
                        <BsBagPlusFill size={70} />
                    </div>
                    <h1 className='font serviceCardDescription' >Lorem ipsum dolor sit amet consectetur. Lacus tincidunt lectus volutpat pharetra velit morbi hendrerit semper.</h1>
                </div>


            </div>
        </div>
    );
}

export default ServicesCompo;
