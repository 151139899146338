import React from 'react';
import moneyImg from "../../assets/Moneyverse Business Analytics.png"
import { IoRocketOutline } from "react-icons/io5";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { IoSyncCircleOutline } from "react-icons/io5";
import "./OurWorkCompo.css"
const OurWorkCompo = () => {
    return (
        <div  className='fullContanier'>

            <div style={{ marginTop: "100px" }} className='continer widthHome '>

                <div className='workMobile workContanier' >

                    <div >
                        <img className='moneyImg' src={moneyImg} alt='p' ></img>
                        <h1 className='font ourWorkImgTitle' >OUR</h1>
                        <h1 className='font ourWorkImgTitle' >MISSION</h1>
                        <div className='sepratorDiv'></div>
                    </div>

                    <div className='workMobile workBigFlex' >

                        <div className='firstDivContanier'>
                            <div style={{ color: "#65C5B9" }}>
                                <IoRocketOutline size={70} />
                            </div>
                            <h1 className='font firstDivTitle' >Confidence</h1>

                            <h1 className='font firstDivDiscription' >Having a sense of self awareness and knowing our strengths and using them to our advantage in a professional environment</h1>
                        </div>




                        <div className='SecondDivContanier'>

                            <div className='firstInside'>
                                <div style={{ color: "#65C5B9" }}>
                                    <MdOutlineRemoveRedEye size={50} />
                                </div>
                                <h1 className='font firstInsideTitle' >Credibility</h1>

                                <h1 className='font firstDivDiscription' >This is what others think of us, our reputation is what drives us, what the client believes about us to be true is just as important as what is actually true</h1>
                            </div>


                            <div className='SecondInside'>
                                <div style={{ color: "#65C5B9" }}>
                                    <IoSyncCircleOutline size={50} />

                                </div>
                                <h1 className='font' style={{ color: "#000000", fontSize: "32px", fontWeight: "600", lineHeight: "34.85px" }}>Competence</h1>

                                <h1 className='font' style={{ fontSize: "16px", color: "#000000B8", lineHeight: "17.42px", fontWeight: "400", textAlign: "center" }}>Having the right skills and knowledge to meet the demands of a particular situation and the ability to apply them efficiently to bring about change</h1>
                            </div>

                        </div>

                    </div>

                </div>



            </div>

        </div>

    );
}

export default OurWorkCompo;
