import React from 'react';
import { Button } from 'react-bootstrap';
import NavBarCompo from './components/NavBar/NavBarCompo';
import HomeCompo from './components/HomeCompo/HomeCompo';
import AboutCompo from './components/AboutCompo/AboutCompo';
import ServicesCompo from './components/servicesCompo/ServicesCompo';
import OurWorkCompo from './components/OurWorkCompo/OurWorkCompo';
import TestimonialCompo from './components/TestimonialCompo/TestimonialCompo';
import FAQCompo from './components/FAQCompo/FAQCompo';
import ContactCompo from './components/contactCompo/ContactCompo';
import FooterCompo from './components/FooterCompo/FooterCompo';
import Navbar2 from './components/NavBar2/NavBar2';
import BussinesModal from './components/BussinesModal/BussinesModal';
import WhyCome from './components/WhyCome/WhyCome';
import ENGAGEMENT from './components/ENGAGEMENT/ENGAGEMENT';
import SMEs from './components/SMEs/SMEs';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';

// ...

const App = () => {
  return (
    <div>
      <Navbar2 />

      <div id="home">
        <Zoom>
          <HomeCompo  />
        </Zoom>
      </div>

   

      <div id="about">
        <Zoom>
          <AboutCompo  />
        </Zoom>
      </div>

      <div id="services">
        <Zoom>
          <ServicesCompo  />
        </Zoom>
      </div>

      <div id="our-work" >
      <Zoom>
        <OurWorkCompo />
      </Zoom>
      </div>

      <Zoom>
        <BussinesModal/>
      </Zoom>

      <Zoom>
        <WhyCome/>
      </Zoom>

      <Zoom>
        <ENGAGEMENT/>
      </Zoom>

      <Zoom> 
          <SMEs/>
      </Zoom>


      <div id="testimonials" >
        <Zoom>
          <TestimonialCompo />
        </Zoom>
      </div>

      <Zoom>
        <FAQCompo />
      </Zoom>

      <div id="contact" >
        <Zoom>
          <ContactCompo/>
        </Zoom>
      </div>

      <FooterCompo />
    </div>
  );
};

export default App;
