import React from 'react';
import "./contactCompo.css"
const ContactCompo = () => {
  return (
    <div style={{ marginTop: "100px" }} className='continer widthHome'>
      <div >
        <div className='ContactHeader'>
          <h1 className='contactText'>Let’s Connect  ></h1>
        </div>


        <div style={{ marginTop: "35px" }}>

          <form>

            <input type='text' placeholder='Name' className='contactInput'></input>
            <input type='number' placeholder='phone' className='contactInput'></input>
            <textarea placeholder='Message' className='contactInput'></textarea>
            <div className='contactButton'>
              <h1 className='buttonText'>Send your message</h1>
            </div>
          </form>

        </div>
      </div>
    </div>
  );
}

export default ContactCompo;
