import React, { useState } from 'react';
import './NavBar2.css';
import { Link } from 'react-scroll';
import logo from '../../assets/Content.png';
import { FaBars } from 'react-icons/fa';

const Navbar2 = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);

  const toggleMobileNav = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };

  return (
    <nav className="navbar">
      <div className="logo">
        <img src={logo} alt="p"  className='imgLogo'></img>
      </div>

      {/* Desktop navigation */}
      <div className="desktop-nav">
        <Link to="home" smooth={true} duration={500} className='pointerNav'>
          Home
        </Link>
        <Link to="about" smooth={true} duration={500} className='pointerNav'>
          About
        </Link>
        <Link to="services" smooth={true} duration={500} className='pointerNav'>
          Services
        </Link>
        <Link to="our-work" smooth={true} duration={500} className='pointerNav'>
          Our Work
        </Link>
        <Link to="testimonials" smooth={true} duration={500} className='pointerNav'>
          Testimonials
        </Link>
        <Link to="contact" smooth={true} duration={500} className='pointerNav'>
          Contact
        </Link>
      </div>

      {/* Mobile navigation */}
      <div className="mobile-nav">
        <button onClick={toggleMobileNav}>
          <FaBars size={25} />
        </button>
        {isMobileNavOpen && (
          <div className="mobile-nav-links">
            <Link to="home" smooth={true} duration={500} className='pointerNav'>
              Home
            </Link>
            <Link to="about" smooth={true} duration={500} className='pointerNav'>
              About
            </Link>
            <Link to="services" smooth={true} duration={500} className='pointerNav'>
              Services
            </Link>
            <Link to="our-work" smooth={true} duration={500} className='pointerNav'>
              Our Work
            </Link>
            <Link to="testimonials" smooth={true} duration={500} className='pointerNav'>
              Testimonials
            </Link>
            <Link to="contact" smooth={true} duration={500} className='pointerNav'>
              Contact
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar2;
