import React from 'react';
import { FaQuoteLeft } from "react-icons/fa";
import "./TestimonialCompo.css"
const TestimonialCompo = () => {
    return (
        <div style={{ marginTop: "100px" }} className='continer widthHome'>

            <div className='font TestimonialTitle' >
                Testimonial
            </div>

            <div className='sirveceMobile TestimonialContanier' >




                <div className='TestimonialCard'>
                    <div style={{ color: "#65C5B9" }}>
                        <FaQuoteLeft size={70} />
                    </div>
                    <h1 className='font TestimonialCardDescription' >Your app brings so much peace and tolerance to our home.</h1>
                </div>

                <div className='TestimonialCard'>
                    <div style={{ color: "#65C5B9" }}>
                        <FaQuoteLeft size={70} />
                    </div>
                    <h1 className='font TestimonialCardDescription' >Your app brings so much peace and tolerance to our home.</h1>
                </div>

                <div className='TestimonialCard'>
                    <div style={{ color: "#65C5B9" }}>
                        <FaQuoteLeft size={70} />
                    </div>
                    <h1 className='font TestimonialCardDescription' >Your app brings so much peace and tolerance to our home.</h1>
                </div>






            </div>

        </div>
    );
}

export default TestimonialCompo;
