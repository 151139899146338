import React from 'react';
import whycome from "../../assets/Picture3.png";
import whyComeMobile1 from "../../assets/Picture14.png"
import whyComeMobile2 from "../../assets/Picture10.png"

import "./WhyCome.css";
const WhyCome = () => {
  return (
    <div style={{ marginTop: "100px" }} className='continer widthHome2'>
        <div className='font aboutTitle'>WHY COME TO US?</div>
        <img src={whycome} alt='p' style={{maxWidth:"100%"}} className='WhyImg'></img>
        <div className='WhycomeMobile'>
            <img src={whyComeMobile1} style={{maxWidth:"100%"}}  alt='p'></img>
            <img src={whyComeMobile2} style={{maxWidth:"100%"}}  alt='p'></img>
        </div>
    </div>
  );
}

export default WhyCome;
