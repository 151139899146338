import React from 'react';
import "./SMEs.css"
import semImg from "../../assets/Picture18.jpg"
const SMEs = () => {
  return (
    <div style={{ marginTop: "100px" }} className='continer widthHome'>
        <div  className='font engamnetTitle'>SMEs vs. Economy</div>
            <div className='flexSme'>
                <p className='font pargraphSME' >Small and medium-sized enterprises (SMEs) are the backbone of many economies, providing jobs and economic growth. However, despite their importance, the majority of SMEs fail within the first few years of operation. There are a variety of factors that contribute to this high failure rate, including inadequate capital, poor management, lack of market knowledge, and competition.</p>
                <img src={semImg} alt='p' style={{display:"block"}}></img>
            </div>
        <div className='SMECardContainer'>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Inadequate capital </h1>
            <p className='font' style={{fontSize:"12px"}}>Limits their ability to invest in resources such as marketing, research and development, and technology. Without these investments, it is difficult for SMEs to compete with larger companies and establish a. Additionally, inadequate capital can lead to cash flow problems, making it difficult for SMEs to pay their bills and stay afloat.
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Poor management </h1>
            <p className='font' style={{fontSize:"12px"}}>Lack the necessary business skills and experience to effectively manage their companies. Without proper management, SMEs can quickly become overwhelmed by the complexities of running a business. Additionally, poor management can lead to inefficient use of resources, resulting in higher costs and lower profits.
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Lack of market knowledge</h1>
            <p className='font' style={{fontSize:"12px"}}>Thorough understanding of their target market, leading to ineffective marketing strategies and poor product positioning. Additionally, without a clear understanding of the competitive landscape, SMEs can struggle to differentiate themselves from their competitors and find success.
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Competition</h1>
            <p className='font' style={{fontSize:"12px"}}>With so many SMEs competing for the same customers, it can be difficult to stand out from the crowd. Additionally, larger companies often have more resources, allowing them to outspend and outmaneuver smaller competitors. This can make it difficult for SMEs to gain traction and establish a foothold in the market.
            </p>
        </div>
    </div>
      
    </div>
  );
}

export default SMEs;
