import React from 'react';
import engamnetImg from "../../assets/Picture16.png"
import "./ENGAGEMENT.css"
const ENGAGEMENT = () => {
  return (
    <div style={{ marginTop: "100px" }} className='continer widthHome'>
    <div  className='font engamnetTitle'>ENGAGEMENT PROCESS</div>
    <img src={engamnetImg} alt='p' style={{maxWidth:"100%",display:"block"}}></img>
    <div className='EngagementCardContainer'>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Identifying</h1>
            <p className='font' style={{fontSize:"12px"}}>Identification of the issue or problem that needs to be addressed. The parties involved should then agree on the goals and objectives of the consultation process. This could include identifying the stakeholders, understanding their interests, and determining the best way to reach a consensus.</p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Scoping</h1>
            <p className='font' style={{fontSize:"12px"}}>The parties should then identify the resources that will be needed to complete the consultation process. This could include research materials, data, and other information that will help inform the discussion.
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Time framing</h1>
            <p className='font' style={{fontSize:"12px"}}>Develop a timeline for the consultation process. This should include when the consultation process will begin, how long it will take, and when it will end. This timeline should also include any milestones or deadlines that need to be met.
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Brainstorming</h1>
            <p className='font' style={{fontSize:"12px"}}>Discuss the issue or problem at hand, share their views, and come to a consensus. This could involve brainstorming ideas, debating different points of view, and exploring potential solutions
            </p>
        </div>
        <div>
            <h1 className='font' style={{fontSize:"22px"}}>Get to action</h1>
            <p className='font' style={{fontSize:"12px"}}>Parties should review the agreement and make any necessary changes. This could include revising the timeline, adjusting the format of the consultation process, or making any other changes that are needed
            </p>
        </div>
    </div>
    </div>
  );
}

export default ENGAGEMENT;
