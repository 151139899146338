import React from 'react';
import bussinesImg from "../../assets/Picture2.png"
import "./BussinesModal.css"

const BussinesModal = () => {
    return (
        <div style={{ marginTop: "100px" }} className='continer widthHome'>
            <div className='font aboutTitle'>BUSINESS MODEL</div>

            <div style={{display:"flex",alignItems:"center",justifyContent:"center",gap:"25px"}} className='bussinesMobile'>
                <div >
                    <div style={{maxWidth:"250px"}} className='bussinesCard' >
                        <h1 className='font' style={{fontSize:"22px"}}>Teamwork</h1>
                        <p className='font' style={{fontSize:"12px"}}>Our team consists of experienced professionals who have a vast amount of knowledge and expertise in their respective fields. We have experts in various areas such as finance, accounting, marketing, human resources, and IT. Our team is well-versed in the latest trends and technologies in their respective fields, allowing us to provide our clients with the most up-to-date advice and assistance.
                        </p>
                    </div>
                    <div style={{maxWidth:"250px"}} className='bussinesCardNone'>
                        <h1 className='font' style={{fontSize:"22px"}}>Experience</h1>
                        <p className='font' style={{fontSize:"12px"}}>We offer a wide range of services that can be tailored to meet the specific needs of our clients. We provide strategic planning, market analysis, financial analysis, operational reviews, and risk management services. We also provide training and development services to ensure that our clients have the skills and knowledge they need to succeed.
                        </p>
                    </div>
                </div>
                <div>
                    <img src={bussinesImg} alt='p' style={{width:"450px"}}></img>
                </div>
                <div   >
                    <div style={{maxWidth:"250px"}} className='bussinesCard'>
                        <h1 className='font' style={{fontSize:"22px"}}>Critical Thinking</h1>
                        <p className='font' style={{fontSize:"12px"}}>We also offer a variety of other services such as training, research, and project management. We have a wide range of resources at our disposal, which allows us to provide our clients with the most comprehensive solutions. We are also committed to staying up-to-date on the latest industry trends and technologies, so that we can provide our clients with the most up-to-date advice and assistance.
                        </p>
                    </div>
                    <div style={{maxWidth:"250px"}} className='bussinesCardNone'>
                        <h1 className='font' style={{fontSize:"22px"}}>Transparency</h1>
                        <p className='font' style={{fontSize:"12px"}}>We understand that each client has unique needs and requirements, and we strive to tailor our services to meet those needs. We take the time to get to know our clients and their businesses, so that we can provide them with the best possible solutions. We also make sure that our clients are kept informed throughout the entire process, so that they can make the best decisions for their business.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default BussinesModal;
