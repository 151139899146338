import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../../assets/Content.png"
import "./NavBar.css"
import { Link, Element } from 'react-scroll';

const NavBarCompo = () => {
  return (
  <>
  
  <Navbar expand="lg" style={{ backgroundColor: "#65C5B9"}} className='navmobile'>
      <Container fluid>
      <Navbar.Brand>
        <img src={logo} alt='p' style={{ width: "79.45px", height: "47.69px" }}></img>
      </Navbar.Brand>
  
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" sty >
          <Nav
          className="me-auto ms-auto my-2 my-lg-0 navmobile mr-auto"
          style={{marginRight:"0px"}}
          navbarScroll
            
          >

          <Nav.Link>
          <Link
             activeClass="active"
             
             spy={true}
             smooth={true}
             offset={-70}
             duration={500}
             style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
          >
             Home
          </Link>
       </Nav.Link>



      <Nav.Link>
      <Link
         activeClass="active"
         to="About"
         spy={true}
         smooth={true}
         offset={-70}
         duration={500}
         style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
      >
      About
      </Link>
   </Nav.Link>

   
      <Nav.Link>
      <Link
         activeClass="active"
         to="Services"
         spy={true}
         smooth={true}
         offset={-70}
         duration={500}
         style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
      >
      Services
      </Link>
   </Nav.Link>
   

   <Nav.Link>
   <Link
      activeClass="active"
      to="ourWork"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
   >
   Our Work
   </Link>
</Nav.Link>

<Nav.Link>
<Link
   activeClass="active"
   to="Testimonials"
   spy={true}
   smooth={true}
   offset={-70}
   duration={500}
   style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
>
Testimonials
</Link>
</Nav.Link>


<Nav.Link>
<Link
   activeClass="active"
   to="Contact"
   spy={true}
   smooth={true}
   offset={-70}
   duration={500}
   style={{ color: "#474C4C", fontWeight: "500", fontSize: "15px", lineHeight: "18.29px" }}
>
Contact
</Link>
</Nav.Link>


   

          </Nav>
        </Navbar.Collapse>
      
      </Container>
    </Navbar>

  

    


  </>
  );
}

export default NavBarCompo;
