import React from 'react';
import image from "../../assets/Rectangle 1.png";
import "./HomeCompo.css";

const HomeCompo = () => {
  return (
    <div style={{ marginTop: "100px" }} className='continer widthHome'>
      <div className='mobileViewHome HomeCompo'>
        <div>
          <h1 className='font TitleHome'>Welcome To Watad </h1>
          <h1 className='font TitleHome'>Lorem ipsum</h1>
          <h1 className='font subTitleFirst'>get impressive ideas for your product visual identity</h1>
          <h1 className='font subTitleDiscription'>Watad is a Limited liability company established in 2021, to provide comprehensive and consultation services across a wide range of industries. Founded by a group of seasoned and licensed professionals who possess exceptional interpersonal, problem-solving and analytical skills to provide advice and expertise to client organizations improving business performance</h1>
          <div className='buttonDiv'>
            <h1 className='font buttonStyle' >Let’s Connect</h1>
          </div>
        </div>
        <div>
          <img src={image} alt='p' className='imgStyle'></img>
        </div>
      </div>
    </div>
  );
}

export default HomeCompo;
