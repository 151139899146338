import React from 'react';
import { FaLinkedin } from "react-icons/fa";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaTwitterSquare } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import "./FooterCompo.css"
import { Link } from 'react-scroll';


const FooterCompo = () => {
    return (
        <div className='footerFullContanier'>
            <div style={{ marginTop: "100px" }} className='continer widthHome'>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} className='footerMobile'>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", gap: "5px" }}>
                        <div style={{ color: "#3158A5" }}>
                            <FaLinkedin size={35} />
                        </div>
                        <div style={{ color: "#3158A5" }}>
                            <FaSquareFacebook size={35} />
                        </div>
                        <div style={{ color: "#39A5E2" }}>
                            <FaTwitterSquare size={35} />
                        </div>
                        <div style={{ color: "#EF27ABBD" }}>
                            <FaInstagramSquare size={35} />
                        </div>
                        <div style={{ color: "#000000" }}>
                            <FaTiktok size={35} />
                        </div>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "15px" }}>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "15px" }}>
                            <Link to="home" smooth={true} duration={500} className='pointerNav font navFooter' >
                                Home
                            </Link>
                            <Link to="about" smooth={true} duration={500} className='pointerNav font navFooter' >
                                About
                            </Link>
                            <Link to="services" smooth={true} duration={500} className='pointerNav font navFooter'>
                                Services
                            </Link>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", gap: "15px" }}>
                            <Link to="our-work" smooth={true} duration={500} className='pointerNav font navFooter'>
                                Our Work
                            </Link>
                            <Link to="testimonials" smooth={true} duration={500} className='pointerNav font navFooter'>
                                Testimonials
                            </Link>
                            <Link to="contact" smooth={true} duration={500} className='pointerNav font navFooter'>
                                Contact
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='font' style={{ color: "#FFFFFF", textAlign: "center", fontSize: "15px", fontWeight: "700", lineHeight: "18.29px", marginTop: "50px" }}>copy right reserved Tasaro 2023</div>
            </div>
        </div>
    );
}

export default FooterCompo;
